import IconChooseCountry from "@/components/icons/IconChooseCountry.vue";
import { mapGetters, mapActions } from "vuex";

export default {

    components: {
        IconChooseCountry
    },
    computed: {
        ...mapGetters({
            regionsList: 'regions/regionsList',
            regionsListLoaded: 'regions/regionsListLoaded'
        }),
        sortedRegionList() {
            return this.regionsList.slice().sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    async created() {
        await this.GET_REGIONS_LIST();
        this.checkGeolocation();
    },
    methods: {
        ...mapActions({
            GET_REGIONS_LIST: 'regions/GET_REGIONS_LIST'
        }),
        selectCountry(alpha2code) {
            if (alpha2code == 'CZ') {
                localStorage.setItem('app_locale', 'cz')
            } else {
                localStorage.setItem('app_locale', 'en')
            }
            localStorage.setItem('country', alpha2code);
            localStorage.removeItem('products')
            window.location.reload();
        },
        checkGeolocation() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    this.handleGeolocationSuccess,
                    this.handleGeolocationError
                );
            }
        },
        async handleGeolocationSuccess(position) {
            try {
                const response = await fetch(
                    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`
                );
                const data = await response.json();
                const userCountryCode = data.countryCode;

                const matchingCountry = this.countries.find(
                    country => country.alpha2code === userCountryCode
                );

                if (matchingCountry) {
                    this.selectCountry(matchingCountry.alpha2code);
                }
            } catch (error) {
                console.error('Error getting location details:', error);
            }
        },
        handleGeolocationError(error) {
            console.error('Geolocation error:', error);
        }
    }
};