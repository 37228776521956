import Vue from 'vue';
import type {ModuleTree} from 'vuex';
import Vuex from 'vuex';
//auto register modules (/modules)

Vue.use(Vuex);

interface ModuleState {
}

import seminars from './modules/seminars';
import products from './modules/products';
import pages from './modules/pages';
import home from './modules/home';
import setting from './modules/setting';
import popups from './modules/popups';
import documents from './modules/documents';
import courses from './modules/courses';
import order from './modules/order';
import auth from './modules/auth';
import profile from './modules/profile';
import system from './modules/system';
import favorites from './modules/favorites';
import basket from './modules/basket';
import card from './modules/card';
import search from './modules/search';
import remainings from './modules/remainings';
import country from './modules/country';
import zasilkovna from "./modules/zasilkovna";
import regions from "./modules/regions";
import promotions from './modules/promotions';
import brands from './modules/brands'
import esputnik from './modules/esputnik'


const modules: ModuleTree<ModuleState> = {
  seminars,
  products,
  pages,
  home,
  setting,
  popups,
  documents,
  courses,
  order,
  auth,
  profile,
  system,
  favorites,
  basket,
  card,
  search,
  remainings,
  country,
  zasilkovna,
  promotions,
  brands,
  regions,
  esputnik,
};

/*
// Storing in variable a context with all files in folder modules
// ending with `.js` or `.ts`.
const requireModule = require.context('./modules/', false, /(\.js|\.ts)$/)
const modules: ModuleTree<ModuleState> = {};

requireModule.keys().forEach(fileName => {
  if (fileName === './index.ts') return
  // filter fullstops and extension
  // and return a camel-case name for the file
  const moduleName = camelCase(
    fileName.replace(/(\.\/|\.js|\.ts)/g, '')
  )
  // create a dynamic object with all modules
  modules[moduleName] = {
    // add namespace here
    namespaced: true,
    ...requireModule(fileName).default
    // if you have exported the object with name in the module file
    // e.g., export const name = {};
    // uncomment this line and comment the above
    // ...requireModule(fileName)[moduleName]
  }
})
*/

export interface RootState {
}

export default new Vuex.Store({
  modules,
});
