// import mainInput from '../../atoms/main-input/index.vue'
// import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneMaskInput from "vue-phone-mask-input";

import { email, helpers, maxLength, minLength, required, sameAs } from "vuelidate/lib/validators";
export const RegisterMode = {
    None: "none",
    Regular: "regular",
    Professional: "professional",
    Other: "other",
    Distributor: "distributor",
};
import validation from "@/mixins/validation";
import { phoneNumberValidation, latinOnly } from "../../../mixins/validation";

export default {
    name: "registration",

    data() {
        return {
            RegisterMode,
            agree: false,
            payload: {
                register_mode: RegisterMode.Regular,
                email: "",

                phone_country: "",
                countryCode: null,
                country: null,
                selectedCountry: null,
                filterCountries: [],
                countries: [],

                city: null,
                selectedCity: null,

                password: "",
                password_confirmation: "",
                name: "",
                last_name: "",
                assign_to: "",
                phone: "",
                work: "",
            },
            countries: [],
            filterCountries: [],
            validationErrors: {},
            validationFiles: false,
            showPassword: false,
            registrationMode: RegisterMode.None,
            registrationModes: [
                {
                    mode: RegisterMode.Regular,
                    title: this.$t("patientRegistration"),
                    enabled: true,
                },
                {
                    mode: RegisterMode.Professional,
                    title: this.$t("professionalRegistration"),
                    enabled: true,
                },
                {
                    mode: RegisterMode.Other,
                    title: this.$t("otherRegistration"),
                    enabled: true,
                },
                {
                    mode: RegisterMode.Distributor,
                    title: this.$t("distributorRegistration"),
                    enabled: false,
                },
            ].filter(({ enabled }) => enabled),
            rule: {
                require: true,
            },
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            phone: {
                required,
            },
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
            country: {
                required,
            },
            selectedCountry: {
                required,
            },
            city: {
                required,
            },
            selectedCity: {
                required,
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
                sameAsPassword: sameAs("password"),
            },
            password_confirmation: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
                sameAsPassword: sameAs("password"),
            },
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(120),
                latinOnly
            },
            last_name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(120),
                latinOnly
            },
        },
    },
    components: {
        PhoneMaskInput
        // mainInput
    },
    watch: {
        agree() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";
        },
        "payload.countryCode"() {
            this.$refs.phonemask.$forceUpdate();
            this.phoneMaskCode = new Date().getTime();
            this.$refs.phonemask.$el.querySelector("input").value = "";
            this.payload.phone = "";
        },
        registrationMode: function() {
            // if no verification code exists, then we show the sms popup
            if (!this.verifyCodeResponse) {
                // disable sms phone verification
                // this.changeSmsRegisterPopup(true);
                // return;
            }

            // otherwise, we show the registration popup for specific mode
            switch (this.registrationMode) {
                case RegisterMode.Regular:
                    this.changeRegistrationPopup(true);
                    break;
                case RegisterMode.Professional:
                    this.changeRegisterAgentPopup(true);
                    break;
                case RegisterMode.Other:
                    this.changeOtherRegisterPopup(true);
                    break;
                case RegisterMode.Distributor:
                    this.changeRegisterDistributorPopup(true);
                    // this.$toasted.success('this.changeRegisterDistributorPopup(true)')
                    break;
            }
        },
    },
    computed: {
        ...mapGetters({
            loadingRegistration: "auth/loadingRegistration",
            verifyCodeResponse: "auth/verifyCodeResponse",
            cityList: "country/cityList",
        }),
        passwordErrors() {
            return this.computeErrors("payload", "password", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                minLength: { message: "validationMin", count: 8 },
            });
        },
        passwordConfirmationErrors() {
            return this.computeErrors("payload", "password_confirmation", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                sameAsPassword: "validationSameAs",
                minLength: { message: "validationMin", count: 8 },
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
        countryErrors() {
            return this.computeErrors("payload", "selectedCountry", {
                required: "validationRequired",
            });
        },
        cityErrors() {
            return this.computeErrors("payload", "selectedCity", {
                required: "validationRequired",
            });
        },
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
            });
        },
        nameErrors() {
            return this.computeErrors("payload", "name", {
                required: "validationRequired",
                minLength: { message: "validationMin", count: 3 },
                maxLength: { message: "validationMax", count: 120 },
                latinOnly: "onlyLatinLettersAllowed"
            });
        },
        lastNameErrors() {
            return this.computeErrors("payload", "last_name", {
                required: "validationRequired",
                minLength: { message: "validationMin", count: 3 },
                maxLength: { message: "validationMax", count: 120 },
                latinOnly: "onlyLatinLettersAllowed"
            });
        },
    },
    created() {
        this.payload.phone = this.verifyCodeResponse?.phone;
        document.getElementById("html").classList.add("hide");
        this.getCountries();

        this.fetchCountries().then(res => {
            this.countries = res;
            this.filterCountries = res;
        });
    },
    methods: {
        ...mapMutations({
            changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeSuccessRegisterPopup: "popups/CHANGE_SUCCESS_REGISTER_POPUP",
            changeRegisterAgentPopup: "popups/CHANGE_AGENT_REGISTER_POPUP",
            changeOtherRegisterPopup: "popups/CHANGE_OTHER_REGISTER_POPUP",
            changeRegisterDistributorPopup: "popups/CHANGE_DISTRIBUTOR_REGISTER_POPUP",
            changeSmsRegisterPopup: "popups/CHANGE_PHONE_REGISTER_POPUP",
        }),
        ...mapActions({
            registrations: "auth/REGISTRATION",
            fetchUser: "profile/FETCH_USER_DATA",
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            getCountries: "auth/GET_COUNTRIES",
            fetchCountries: "country/GET_COUNTRY_LIST",
            fetchCities: "country/GET_CITY_LIST",
        }),
        handleCountyInput(e) {
            if(e.length < 3) {
                this.filterCountries = this.countries.slice();
                return;
            }
            this.filterCountries = this.countries.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleSelectCountry(item) {
            this.payload.countryCode = item.code;
            this.payload.selectedCountry = item.name;
            this.payload.city = '';
            this.fetchCities({ code: item.code }).then(res => console.log(res));
        },
        handleCityInput(e) {
            if(e.length < 3) {
                this.fetchCities({ code: this.payload.countryCode }).then(res => console.log(res));
            }
            this.fetchCities({ code: this.payload.countryCode, search: e }).then(res => console.log(res));
        },
        handleSelectCity(item) {
            this.payload.selectedCity = item.name;
        },
        async submit() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";

            this.$v.$touch();

            if (this.$v.$invalid || !this.agree) {
                console.log('Form invalid', this.$v.$invalid, this.$v);
                return;
            }

            let parsedNumber = '';

            try {
                const phone = this.payload.phone;
                if (phone) {
                    parsedNumber = parsePhoneNumberFromString(phone.startsWith('+') ? phone : '+' + phone);
                }
            } catch (error) {
                console.error('Error parsing phone number:', error);
                parsedNumber = '';
            }

            if (parsedNumber) {
                this.payload.phone_country = parsedNumber.country;
            } else {
                this.payload.phone_country = "";
            }

            this.payload.confirm_token = this.verifyCodeResponse?.confirmToken;

            try {
                await this.registrations(this.payload);

                this.changeRegistrationPopup(false);

                this.fetchUser().then(() => {
                    if (this.payload.assign_to.length) {
                        this.changeRegistrationPopup(false);
                        // this.changeSuccessRegisterPopup(true, {text: ''})
                        this.$router
                            .push({ name: "profile" })
                            .then(() => {})
                            .catch(() => {});
                    }
                });
            } catch (error) {
                this.handleRegistrationErrors(error);
            }
        },
        handleRegistrationErrors(error) {
            if (!error?.data?.errors) {
                return;
            }

            const processErrors = ["email", "phone", "assignTo"];
            for (const processErrorsKey of processErrors) {
                const errors = error.data.errors[processErrorsKey];
                if (!errors || !errors[0]) {
                    continue;
                }

                this.$toasted.error(errors[0]);
            }
        },
    },
};
