//utils
import "./utils/plugins";
import "./utils/translations";
import "./utils/global-components";
import VueTheMask from "vue-the-mask";
import VueSmoothScroll from "vue2-smooth-scroll";
import moment from "moment";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import VueHead from "vue-head";

import VTooltip from "v-tooltip";
import { $http } from "@/utils/https";

Vue.use(VTooltip)

Vue.use(VueSmoothScroll, {
    duration: 400,
    offset: -380,
});
// moment
Vue.prototype.moment = moment;
Vue.use(VueTheMask);

Vue.use(VueHead);
//vue configs
Vue.config.productionTip = false;

//set token after refresh page
if (localStorage.getItem("user_token")) {
    store.commit(`auth/SET_TOKEN`, localStorage.getItem("user_token"));
}

const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event("render-event"));
    },
});

const eventHub = new Vue();
Vue.mixin({
    data: function() {
        return {
            eventHub: eventHub,
        };
    },
});

// Костиль для фікса бага з перекладами. Чогось йде 2 запити на переклади
// 1 - має дефолтну мову
// 2 - має мову яку вибрав юзер
// Проблема в тому що ініт відбувається після першого запиту з дефолтною мовою і реініт не відбувається.
const initTranslations = async () => {
    if (!localStorage.getItem('app_locale')) {
        localStorage.setItem('app_locale', 'en');
    }
    const appLocale = localStorage.getItem('app_locale') || 'en';
    const response = await $http.get(`v1/regions/current`);
    if (localStorage.getItem('country') !== response.data.alpha2Code) localStorage.setItem('country', response.data.alpha2Code)
    setTimeout(() => {
        // @ts-ignore
        window.currentLocale = appLocale;
        // @ts-ignore
        Vue.initI18nManager().then(() => {
            // @ts-ignore
            const urlLocale = app._route.params?.lang;
            // @ts-ignore
            if (!urlLocale || urlLocale !== window.currentLocale) {
                // @ts-ignore
                const updatedParams = { ...app._route.params, lang: window.currentLocale };

                // @ts-ignore
                router.push({ params: updatedParams, query: app._route.query }).then(() => {
                // @ts-ignore
                    console.log("URL updated with the correct locale:", window.currentLocale);
                }).catch(err => {
                    console.error("Error updating the route:", err);
                });
            }
            try {
                app.$mount("#app");
            } catch (e) {}
        });
    }, 500)
}

initTranslations();
