import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";

interface State {
    loading: boolean;
}

const state: State = {
    loading: false,
}

const getters = {
    loading: (state: State) => state.loading,
}

const actions = {
    ESPUTNIK_SUBSCRIBE: async ({commit}: ActionContext<State, RootState>, payload: {locale: string, email: string}) => {
        const response = await $http.post(`v1/regions/subscribe_email`, payload);
        return response.data;
    },
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
